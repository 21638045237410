.bankGuaranteeChat__caption-block {
    margin-bottom: 45px;
}
.bankGuaranteeChat__caption {
    font-size: 24px;
    line-height: 22px;
}
.bankGuaranteeChat__subcaption-block {
    margin-bottom: 20px;
}
.bankGuaranteeChat__subcaption {
    font-size: 18px;
}
.bankGuaranteeChat__link {
    font-size: 18px;
    text-decoration-line: underline;
    color: #0077B9;
}
.bankGuaranteeChat__inbox-block {
    background: #F4FCFF;
    padding: 15px 15px 20px 15px;
    border-radius: 10px;
    margin-right: 10px;
}
.bankGuaranteeChat__outbox-block {
    background: #F0FFF3;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 15px 15px 20px 15px;
}
.bankGuaranteeChat__file-icon {
    color:#0066A1;
    font-size: 19px;
}
.bankGuaranteeChat__file-text{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 16px;
}
.bankGuaranteeChat__date{
    font-size: 12px;
    color: #979797;
    margin: 15px 12px 5px 12px;
}
.bankGuaranteeChat__comment{
    font-size: 16px;
    margin-bottom: 20px;
}
.bankGuaranteeChat__inbox-taskName{
    font-size: 16px;
    margin-bottom: 20px;
}
.bankGuaranteeChat__inbox-comment{
    font-size: 16px;
    margin-bottom: 20px;
}
.bankGuaranteeChat__state{
    font-size: 16px;
    margin-bottom: 15px;
}
.bankGuaranteeChat__file-link{
    font-size: 16px;
}
.bankGuaranteeChat__btn{
    background: #F4FCFF;
    border-radius: 30px;
}
.bankGuaranteeChat__answer-decision{
    font-size: 16px;
    margin-bottom: 10px;
}
.bankGuaranteeChat__answer-comment{
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 15px;
}
.bankGuaranteeChat__answer-documents{
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 15px;
}
.bankGuaranteeChat__answer-file {
    margin-left: 20px;
}
.bankGuaranteeChat__answer-file-text {
    font-size: 14px;
    color:#0066A1;
    margin-left: 10px;
}
.bankGuaranteeChat__answer-documents-icon {
    color:#0066A1;
}
.bankGuaranteeChat__answer-rework{
    padding-top: 10px;
}
