.attachments {
  &__header {
    display: table;
    width: 100%;
    text-align: right;
    border-bottom: 1px solid rgba(144, 156, 170, 0.51);
    margin-bottom: 20px;
  }

  &__header-title {
    display: table-cell;
    text-align: left;
    vertical-align: bottom;
  }

  &__header-button {
    display: table-cell;
    text-align: right;
    vertical-align: bottom;
    //padding-bottom: 5px;
  }

  &__header-title-label {
    display: inline-block;
    margin-bottom: 8px;
    line-height: 1;
  }

  &__header-info {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
  }

  &__btn-add{
    padding-right: 0 !important;
    padding-bottom: 4px !important;

    &:disabled {
      cursor: default !important;
      color: #555 !important;
    }
  }

  &__select-btn {
    display: inline-block;
  }

  &__simple-form {
    display: table;
    width: 100%;
  }

  &__simple-form-cell {
    position: relative;
    vertical-align: middle;
    display: table-cell;
    text-align: right;
    padding-left: 10px;
  }

  &__simple-form-input {
    padding-right: 15px;

    &--nodesc {
      width: 50px;
    }
  }

  &__info {
    padding-left: 15px;
    font-size: 12px;
    width: 100%;
  }

  &__info-desc {
    color: black;
    text-align: right;
    font-weight: bold;
  }

  &__plug-info {
    margin-top: 3px;
  }

  &__extra-info {
    margin-top: 7px;
  }

  &__description-warn {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__description-warn-table {
    display: table;
    height: 100%;
    width: 100%;
  }

  &__description-warn-cell {
    padding-left: 15px;
    display: table-cell;
    vertical-align: middle;
    background: #fff;
  }

  &__description, &__file {
    width: 100%;
  }

  &__description {
    height: 36px;
  }

  &__file {
    height: 0;
    margin-bottom: 20px;
  }

  &__file-upload {
    display: none;
    pointer-events: none;
  }

  &__file-form {
    margin-top: 0;
    margin-bottom: 0 !important;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &__file-select-btn {
    margin-top: 10px;
  }

  &__list {
    margin-bottom: 0 !important;
    //margin-top: 8px;
  }

  &__list-title {
    text-transform: none;
    padding-bottom: 7px
  }

  &__list-body {
    margin-bottom: 0 !important;
  }

  &__list-body-table {
    //border-bottom: 1px solid #ddd;
  }

  &__list-item {
    background-color: inherit;
    &--selected {
      background-color: #efefef !important;
    }
  }

  &__accept-buttons {
    margin-right: 15px;
    margin-bottom: 10px;
    text-align: right;
  }

  &__list-item-cell {
    height: 56px;
    padding: 0 12px !important;
    border-top: none !important;

    vertical-align: middle !important;
  }

  &__list-item-cell_preview-bord {
    padding: 0 !important;
    width: 8px;
  }

  &__list-item-cell_preview {
    padding: 0 !important;
    width: 20%;
    text-align: center;
  }

  &__list-item-text {
    display: inline-block;
    margin-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__list-item-cell_buttons {
    padding-right: 12px !important;
    width: 20%;
    text-align: right;
  }

  &__buttons-delim {
    display: inline-block;
    width: 1px;
    height: 16px;
    background: #eee;
    vertical-align: middle;
    margin: 0 1px;
  }

  &__list-item-btn {
    &--disabled {
      background: none;
      color: #333 !important;
    }
  }

  &__list-preview {
    max-width: 56px;
    max-height: 100%;
    margin: 5px 0;
  }

  &__list-no-preview {
    padding: 8px 0;
    height: 48px;
    width: 48px;
  }
}
