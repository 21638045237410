.position_checkbox {
    margin-left: -5px;
}

.position_text_checkbox {
    margin-left: -7px;
}

.checkbox_form_group {
    margin-bottom: 0;
}