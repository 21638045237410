.bankGuaranteeLokoChat__caption-block {
    margin-bottom: 45px;
}
.bankGuaranteeLokoChat__caption {
    font-size: 24px;
    line-height: 22px;
}
.bankGuaranteeLokoChat__subcaption-block {
    margin-bottom: 20px;
}
.bankGuaranteeLokoChat__subcaption {
    font-size: 18px;
}
.bankGuaranteeLokoChat__link {
    font-size: 18px;
    text-decoration-line: underline;
    color: #0077B9;
}
.bankGuaranteeLokoChat__block{
    padding: 15px 15px 20px 15px;
    border-radius: 10px;
}
.bankGuaranteeLokoChat__block.inbox {
    background: #F4FCFF;
    margin-right: 10px;
}
.bankGuaranteeLokoChat__block.outbox {
    background: #F0FFF3;
    margin-bottom: 15px;
}
.bankGuaranteeLokoChat__file-icon {
    color:#0066A1;
    font-size: 19px;
}
.bankGuaranteeLokoChat__file-text{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 16px;
}
.bankGuaranteeLokoChat__date{
    font-size: 12px;
    color: #979797;
    margin: 15px 12px 5px 12px;
}
.bankGuaranteeLokoChat__comment{
    font-size: 16px;
    margin-bottom: 20px;
}
.bankGuaranteeLokoChat__stateName{
    font-size: 16px;
    margin-bottom: 20px;
}

.bankGuaranteeLokoChat__state{
    font-size: 16px;
    margin-bottom: 15px;
}
.bankGuaranteeLokoChat__file-link{
    font-size: 16px;
}
.bankGuaranteeLokoChat__btn{
    background: #F4FCFF;
    border-radius: 30px;
}
.bankGuaranteeLokoChat__answer-decision{
    font-size: 16px;
    margin-bottom: 10px;
}
.bankGuaranteeLokoChat__answer-comment{
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 15px;
}
.bankGuaranteeLokoChat__answer-documents{
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 15px;
}
.bankGuaranteeLokoChat__answer-file {
    margin-left: 20px;
}
.bankGuaranteeLokoChat__answer-file-text {
    font-size: 14px;
    color:#0066A1;
    margin-left: 10px;
}
.bankGuaranteeLokoChat__answer-documents-icon {
    color:#0066A1;
}
.bankGuaranteeLokoChat__answer-rework{
    padding-top: 10px;
}
