.maintenance {
  &__info {
    background-image: url('../graphics/wrench.svg');
    background-position: center;
    background-repeat: no-repeat;
    margin: 70px auto 0;
    padding-top: 200px;
    max-width: 1134px;
    min-height: 700px;
    padding-left:0;
    padding-right:0;
  }

  &__title {
    font-family: 'PT Sans Caption', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 47px;
  }

  &__message {
    margin-top: 10px;
    margin-bottom: 35px;
    font-family: 'PT Sans Caption', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
  }

  &__contacts {
    margin-top: 20px;
    display: flex;
  }

  &__contacts-icon, &__contacts-data {
    display: inline-block;
  }

  &__contacts-icon {
    background-image: url('../graphics/support.svg');
    background-size: cover;
    width: 24px;
    height: 24px;
    margin-top: 8px;
  }

  &__contacts-data {
    margin-left: 15px;
  }

  &__contacts-phone {
    font-family: 'PT Sans Caption', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
  }

  &__contacts-about {
    font-family: 'PT Sans Caption', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    color: #979797;
  }

  &__contacts_no_img {
    padding-left: 24px;
  }
}

@media (max-width: 1200px) {
  .maintenance__info {
      padding-left: 50px;
  }
}