.auc-prices {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    padding-top: 10px;
}
.auc-price {
    padding-right: 20px;
}
.auc-price span {
    padding-right: 10px;
}

.auc-best-star {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.14168 0.48702L5.18879 4.10689L0.819452 4.68924C0.035902 4.79313 -0.278116 5.67622 0.290107 6.18202L3.45122 8.99808L2.70356 12.9761C2.56898 13.6952 3.39739 14.2338 4.09122 13.8975L8 12.0192L11.9088 13.8975C12.6026 14.231 13.431 13.6952 13.2964 12.9761L12.5488 8.99808L15.7099 6.18202C16.2781 5.67622 15.9641 4.79313 15.1805 4.68924L10.8112 4.10689L8.85832 0.48702C8.50841 -0.158212 7.49458 -0.166414 7.14168 0.48702Z' fill='%23F5A623'/%3E%3C/svg%3E");
    background-size: 16px 14px;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: center;
    padding-left: 22px;
}
.auction-info {
    background-color: #FDFDFD;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
}
.auction {
    border-radius: 3px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
}
.auc-opener {
    padding: 8px;
    text-align: center;
    border-top: 1px solid #ddd;
}
.auc-open-btn {
    color: #337ab7;
    text-decoration: none;
    padding: 0;
    border: none;
    font-size: 12px;
    line-height: 1.42857143;
}
.auc-process-area {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
}
.auc-state {
    color: #E21A1A;
    font-size: 14px;
}
.auc-lot-num {
    padding-right: 0;
}