.bankGuaranteeApp-headerTab {
    pointer-events:none;
    border: none;
}
.bankGuaranteeApp-headerTab span {
    color: black !important;
}
.bankGuaranteeApp-headerTab .panel-heading{
    background-color:white !important;
}
.bankGuaranteeApp-headerTab .panel{
    border: none;
}
.bankGuaranteeApp .panel-title a[disabled]{
    pointer-events:none
}
.bankGuaranteeApp .btnAdd{
    margin-left: 15px;
}
.bankGuaranteeApp .custom-checkbox{
    margin-top: 25px;
}
.bankGuaranteeApp .additionalInfo .custom-checkbox{
    margin-top: 0 !important;
}
.bankGuaranteeApp__h1{
    font-size: 16px;
    line-height: 19px;
    color: #0066A1;
    font-weight: bold;
    margin-bottom: 0px;
}
.bankGuaranteeApp__h2{
    padding-top: 15px;
    font-size: 14px;
    line-height: 17px;
    color: #4A4A4A;
    font-weight: bold;
}
.bankGuaranteeApp__h3{
    font-size: 11px;
}
.bankGuaranteeApp__block{
    margin-bottom: 15px;
    min-height: 54px;
}
.bankGuaranteeApp__btn-blue{
    background: #0066A1;
    border-radius: 3px;font-size: 12px;
    color: #FFFFFF;
}
.bankGuaranteeApp__btn-blue:hover{
    background: #005784;
    color: #FFFFFF;
}
.bankGuaranteeApp__btn-blue[disabled]{
    background: #0066A1 !important;
}
.bankGuaranteeApp__btn-green{
    background: #5cb85c;
    border-radius: 3px;font-size: 12px;
    color: #FFFFFF;
}
.bankGuaranteeApp__btn-green:hover{
    background: #4cae4c;
    color: #FFFFFF;
}
.bankGuaranteeApp__btn-green[disabled]{
    background: #5cb85c !important;
}
.bankGuaranteeApp__btn-red{
    background: #c9302c;
    border-radius: 3px;font-size: 12px;
    color: #FFFFFF;
}
.bankGuaranteeApp__btn-red:hover{
    background: #ac2925;
    color: #FFFFFF;
}
.bankGuaranteeApp__btn-red[disabled]{
    background: #ac2925 !important;
}
.bankGuaranteeApp__btn-white{
    background: #FFFFFF;
    border: 1px solid #0066A1;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    color: #0066A1;
}
.bankGuaranteeApp__btn-white:hover{
    border: 1px solid #0066A1;
}
.bankGuaranteeApp__alertWorker {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.bankGuaranteeApp__list-icon {
    font-size: 10px;
    position: absolute;
    top: 4px;
    left: -23px;
}

.bankGuaranteeApp__limitsTh {
    color: #9b9b9b;
    font-size: 14px;
    margin: 0;
}

.bankGuaranteeApp__limits {
    color: #333333;
    font-size: 16px;
}
.bankGuaranteeApp__block-answer{
	 margin-bottom: 15px;
	 float: none;
	 padding-right: 0px;
     padding-left: 0px;

}

.bankGuaranteeApp__popup_small {
    width: 180px;
    max-width: 180px;
}


.bankGuaranteeApp__popup {
    width: 400px;
    max-width: 400px;
}

.bankGuaranteeApp__circle-info {
    color: #0066a1; font-size: 14px;
}

.bankGuaranteeApp__tooltip-info-circle {
    color: #0066A1;
    position: relative;
    z-index: 1;
    cursor: default
}
.bankGuaranteeApp__files-main {
    display: flex;
    flex-direction:row;
    justify-content:space-between;
}
.bankGuaranteeApp__files-block {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
    padding-bottom: 3px;
}

.form-control-select{
    height: 34px;
}

.form-control-select .ui-select-choices{
    height: auto!important;
}
.bank-guarantee-limit-btn-margin {
    margin: 20px 0 20px;
}
.bank-guarantee-limit-btn-success-text {
    color: #ff6600;padding-left: 15px
}

.feedback-form-show-bankGuaranteesApp {
    text-align: left;
    text-decoration: underline;
    font-style: normal;
    font-size: 14px;
    line-height: 114%;
    color: #ff6600;
    cursor: pointer;
    margin-bottom: 20px;
}

.feedback-form-bankGuaranteesApp {
    margin-bottom: 20px;
}
.bankGuaranteeApp__importBtn {
    padding-top: 20px;
}
.bankGuaranteeApp__importInfoIcon {
    color: #31708f;
}

.bankGuaranteeApp__agentSelectbox .ui-select-container{
    display: inline-block;
    width: 200px;
    vertical-align: middle;
}