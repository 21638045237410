.bcdiv {
    font-size: 46px;
    display: inline-block;
    margin-right: 15px;
    color: #0066A1;
    height: 87px;
    width: 100%;
}
.document-info {
    margin-left: 8px;
}
.document-title {
    font-family: Verdana;
    padding-bottom: 5px;
}
.document-type {
    font-family: Verdana;
    vertical-align:top;
}
.steps-finish {
    background: #FFFFFF;
    border: 2px solid #0066A1;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 10px 30px 10px 30px;
    font-size: 16px;
    display: inline-block;

    font-family: 'Verdana';
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
    text-align: center;
    color: #0066A1;
}