.logo-padding {
    margin-right: 24.72px;
}

.bg-advert-link {
    width: 250px;
    height: 50px;
    background: #FFFDFB;
    border: 2px solid #FF5000;
    box-sizing: border-box;
    border-radius: 10px;
    padding-top: 11px;
}

.bg-advert-btn-text, .bg-advert-btn-text:hover, .bg-advert-btn-text:active {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #FF5000;
    background-color: transparent;
    border-color: transparent;
    padding-left: 25px;
    text-decoration: none;
}
.absolut-dropdown {
    margin-left: 33px;
    margin-right: 10px;
    width: 26px;
    height: 33px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
}

.title-option {
    height: 39px;
    background: #FFFDFB;
    border-radius: 20px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #272F56;
    text-align: center;
    text-wrap: none;
    padding-top: 8px;
    margin-right: 20px;
    padding-left: 14px;
    padding-right: 13px;
    box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.1);
}
.bgAdvertisingTabs {
    padding-top: 137px;
}
.absolut-effect {
    -webkit-transition: opacity 300ms ease-in-out;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
}
.absolut-transparent {
    opacity:0;
}

@media (max-width: 991px) {
    .absolut-desktop {
        display: none;
    }
    .absolut-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .absolut-mobile-banner {
        background: transparent;
        background-image: url("./resources/mobile-banner.svg");
        background-position-x: center;
        background-repeat: no-repeat;
        background-size: 500px 450px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 400px;
    }
    .title-option {
        margin: 5px;
    }
    .invitation-options {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .tab-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        width: 100%;
    }
    .tab-option {
        width: 335px;
        height: 76px;
        display: flex;
        flex-direction: row;
        justify-content: normal;
        align-items: center;
        background: #FFFDFB;
        border-radius: 20px;
        margin-bottom: 20px;
        box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.1);
    }
    .tab-control {
        font-family: 'Bitter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #272F56;
        text-shadow: 0px 4px 4px rgba(39, 47, 86, 0.2);
        text-align: center;
        padding-bottom: 15px;
        padding-top: 50px;
    }
    .absolut-condition-text {
        flex-grow: 4;
        text-align: left;
        font-size: 18px;
    }
    .absolut-condition {
        width: 111px;
        height: 82px;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        flex-grow: 0;
    }
    .small-absolut-condition {
        width: 110px;
        height: 60px;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        flex-grow: 0;
    }
    .small-tab-option {
        width: 100px;
        height: 94px;
        display: flex;
        flex-direction: column;
        justify-content: normal;
        align-items: center;
        background: #FFFFFF;
        border-radius: 20px;
        margin-bottom: 20px;
        margin-right: 9px;
        margin-left: 9px;
        box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.1);
    }
    .small-absolut-condition-text {
        font-family: 'Bitter';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #272F56;
        text-shadow: 0px 4px 4px rgba(39, 47, 86, 0.15);
    }
}

@media (min-width: 992px) {
    .absolut-banner-container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: -100px;
        margin-left: 0;
    }
    .absolut-banner {
        background: transparent;
        background-image: url("./resources/banner.svg");
        background-position-x: calc(50% + 60rem);
        background-repeat: no-repeat;
        width: 100%;
        height: 1000px;
        position: absolute;
    }

    .absolut-mobile {
        display: none;
    }
    .absolut-options {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
    }
    .tab-options {
        padding-bottom: 74px;
        padding-right: 30px;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: stretch;
        width: 100%;
    }
    .tab-option {
        width: 220px;
        height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: normal;
        align-items: center;
        background: #FFFDFB;
        border-radius: 20px;
        margin-right: 20px;
        box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.1);
    }
    .bgAdvertisingTabs > li.active > a, .bgAdvertisingTabs > li.active > a:focus, .bgAdvertisingTabs > li.active > a:hover {
        color: #FF5000;
        background-color: #FCF7F0;
    }
    .bgAdvertisingTabs > li:focus > a, .bgAdvertisingTabs > li > a:focus, .bgAdvertisingTabs > li:hover > a, .bgAdvertisingTabs > li > a:hover{
        background-color: #FCF7F0 !important;
    }
    .absolut-condition {
        width: 130px;
        flex-grow: 2;
    }
    .absolut-condition-text {
        flex-grow: 1;
        font-size: 16px;
        text-align: center;
    }
}

.absolut-condition {
    height: 82px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
}

.absolut-condition-text {
    font-family: 'Bitter';
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #272F56;
    text-shadow: 0px 4px 4px rgba(39, 47, 86, 0.15);
}

.bgAdvertisingTabs > li > a {
    font-family: 'Bitter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #272F56;
    text-shadow: 0px 4px 4px rgba(39, 47, 86, 0.2);
}


.desktop-icon {
    width: 140px;
    height: 90px;
}

.bank {
    background-image: url("./resources/bank.svg");
}

.mobile-bank {
    background-image: url("./resources/mobile-bank.svg");
}

.calendar {
    background-image: url("./resources/calendar.svg");
}

.mobile-calendar {
    background-image: url("./resources/mobile-calendar.svg");
}

.cards {
    background-image: url("./resources/cards.svg");
}

.mobile-cards {
    background-image: url("./resources/mobile-cards.svg");
}

.desktop-clock {
    background-image: url("./resources/clock.svg");
}

.mobile-clock {
    background-image: url("./resources/mobile-clock.svg");
}

.documents {
    background-image: url("./resources/documents.svg");
}

.mobile-documents {
    background-image: url("./resources/mobile-documents.svg");
}

.notebook {
    background-image: url("./resources/notebook.svg");
}
.mobile-notebook {
    background-image: url("./resources/mobile-notebook.svg");
}

.oldphone {
    background-image: url("./resources/oldphone.svg");
}

.mobile-oldphone {
    background-image: url("./resources/mobile-oldphone.svg");
}

.papers {
    background-image: url("./resources/papers.svg");
}

.mobile-papers {
    background-image: url("./resources/mobile-papers.svg");
}

.ruble {
    background-image: url("./resources/ruble.svg");
}

.mobile-ruble {
    background-image: url("./resources/mobile-ruble.svg");
}

.absolut-phone {
    background-image: url("./resources/phone_mobile.svg");
}
.absolut-telega {
    background-image: url("./resources/tg_mobile.svg");
}
.absolut-mail {
    background-image: url("./resources/mail_mobile.svg");
}
.dropdown-phone {
    background-image: url("./resources/dropdown_phone.svg");
}
.dropdown-tg {
    background-image: url("./resources/dropdown_telega.svg");
}
.dropdown-mail {
    background-image: url("./resources/dropdown_mail.svg");
}
.logo_cross {
    background-image: url("./resources/logo_cross.svg");
    width: 26px;
    height: 27px;
}
.logo_absolut {
    background-image: url("./resources/absolut_logo.svg");
    width: 152px;
    height: 45px;
}

.absolut-vector {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAYAAACEYr13AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHxSURBVDhPjZM9bhRBEIVfz7B/SAj/BJYISSxxCg6AZCc+BsZ24MCJfyJEgi/AARAhIsc3IELyBbAEgWXZkr0zO93+qntm17M7Bt6qdnuq+716VT2reQTJ+U199BvasXWd/jfscEMOmwoWjcjfhDL7igcOia303MA5xDb0zvYeE5kRfsYDuZ7ogMOnKVmL/NC2iXeJpKRVX2YdELhRz5U6WRAp9LZLJDmw6pfEUJnLlYe+ejx9+B+Rds9jeu3FXC5PM13A6cOZJIFXzHGZuJXHvo85pz1O7MQ1oL1dTnzSOeLfZ4UzDiEGzvldhfyUCNpfIGf6LGvtGat15GsX0Ua0Y71dY3uo9wtkp6/EGHd3GhFSQVT6It+awTwiOde3+rETScCu8Td21oiejnB0Oq3smUugGhXDkCfmFOdlcwNpmiZwQa1f9Bg04BqHZPoqmVGGkYlKmh2HjDauyEoTnamy+SUHzXswYg7pGq1Dz2qCk4J8EZ7z24e4zs5r9mq0ZhAGCvU1VkZmXToPcaAxn9KtIHDJ/jHn4uzn34M7+VBhrcBmDqW23SIz+YZsaF0jyxzLua7ofYkcA6P/yv2B+HKRbJi1kKZaWSXINqhCL6h8SztviA6yITowRBcGu5H0106i9GvLLrJhKtBgKlTjMWKCdA9M7eFQV4SwBwAAAABJRU5ErkJggg==");
    background-repeat: no-repeat;
    background-position-y: center;
}
.absolut-caret {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACdSURBVChTfZLBDcIwDEWfK8EMHaFHVugGCIkDI3QyDlzYoCNwZQRmQAJjR1Eb0iTvaP33HUvB0RPD98isZ/owqJDnxAf6YRahV+Upe0a58QrphGLOmh4Ch5ihVJCKcYTCvZOOiwlL0AKDvtenFUVfsGOypY0ATLbhupnHlwXZKRXk5CctstMqyEXnT3aqJ2Sis5GdtKAmNvGC9seBHx3UbWy5TSJUAAAAAElFTkSuQmCC");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    padding-right: 28px;
    padding-bottom: 4px;
}

.absolut-link-menu > li {
    padding-top: 5px;
    padding-bottom: 5px;
}

.absolut-grey-area {
    background: transparent;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 138px;
    overflow: visible;
}

.absolut-container {
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1600px) {
    .absolut-container {
        width: 1240px;
    }
    .absolut-banner-container {
        width: 100%
    }
}

@media (min-width: 1200px) and (max-width: 1599px){
    .absolut-container {
        width: 1240px;
    }
    .absolut-banner-container {
        width: 100%
    }
}
@media (min-width: 992px) and (max-width: 1239px) {
    .absolut-container {
        width: 970px;
    }
    .absolut-banner-container {
        width: 100%
    }
}
@media (max-width: 991px){
    .absolut-container {
        width: 550px;
    }
    .absolut-banner-container {
        width: 560px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1240px) {
    .absolut-banner {
        background-position-x: calc(50% + 50rem);
    }
}

.absolut-link-menu {
    width: 250px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(255, 223, 208, 0.2);
    border-radius: 10px;
}
.absolut-link-menu > li > img {
    padding-left: 33px;
    padding-right: 11.95px;
}
.absolut-link-menu > li > span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #272F56;
}

.absolut-area {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
}

.absolut-add-container {
    background-position-x: right;
    background-repeat: no-repeat;
    padding-bottom: 50px;
    background-size: 617px 409px
}

.absolut-back {
    background: #FCF7F0;
}
.invitation-block {
    margin-right: auto;
    margin-left: auto;
}
.invitation {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 120%;
    color: #272F56;
    padding-bottom: 20px;
}
.invitation2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #272F56;
    padding-bottom: 30px;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-row-left {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}
