.completedwork__pdf_info{
    width: 800px;
    font-family: 'Times New Roman';
    margin-bottom: 15px;
}

.completedwork__pdf_title {
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    padding-bottom: 30px;
}

.completedwork__pdf_orgtable {
    font-size:small;
    font-weight:normal;
}

.completedwork__pdf_orgtable td{
   height: 20px;
}

.completedwork__pdf_orgtable td:first-child{
    text-align:right;
    width:9%;
}

.completedwork__pdf_orgtable td:nth-child(2){
    border-bottom-color:black;
    border-bottom-style:solid;
    border-bottom-width:1px;
    text-align:left;
}

.completedwork__pdf_workstable {
    font-size:small;
}

.completedwork__pdf_workstable tr td, .completedwork__pdf_workstable thead tr th {
    border: 1px solid #000000;
}

.completedwork__pdf_workstable th{
    text-align:center;
    font-weight:normal;
}

.completedwork__pdf_workstable td{
    text-align: right;
}

.completedwork__header {
    position: relative;
}
.completedwork__header h2 {
    padding-right: 40px;
}
.completedwork__links{
    position: absolute;
    right: 5px;
    top: 37px;
    font-size: 18px;
}

.completedwork__sign_alert .alert{
    margin-top: 20px;
}