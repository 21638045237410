.bank-block {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 20px 30px 25px 30px;
    margin-bottom: 20px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    /*width: calc(50% - 19px);*/
    font-size: 16px;
    line-height: 120%;
    height: 380px;
    position: relative;
}

.bank-block p {
    color: #4A4A4A;
    margin: 25px 0 20px 0;
}

.bank-block ul {
    color: #000000;
    line-height: 180%;
    padding-left: 20px;
}

.bank-block button {
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
    background: #FFFFFF;
    text-align: center;
    color: #0066A1;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    height: 40px;
    width: 145px;
    margin-top: 20px;
    position: absolute;
    bottom: 25px;
}

.bank-block button:hover {
    color: #0066A1;
}

@media (min-width: 768px) and (max-width: 991px) {
    .bank-block {
        height: 540px;
    }
    .ppk-bank-block {
        height: 450px;
    }
}