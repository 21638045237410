.alert-panel {
    display: block;
    word-wrap: break-word;
    overflow-wrap: break-word;

    &__icon {
        vertical-align: top;
        margin-right: 5px;
    }

    &__body {
        display: inline-block;
        width: calc(100% - 25px);
    }

    &--hidden {
        display: none;
    }

    &--nowrap {
        margin-bottom: 0;
    }
}

.alert-nomb .alert{
    margin-bottom: 0;
}
