.order-view__order-number {
    color: #00507C;
}

.order-view__order-etp {
    color: #909CAA;
    font-size: 12px;
}

.order-view__order-name {
    font-size: 18px;
}

.order-view__panel_created {
    position: relative;
}

.order-view__section-header {
    font-size: 18px;
    color: #000000;
    padding: 10px 0;
}

.order-view__section-label {
    color: #979797;
    font-size: 14px;
}

.order-view__row {
    padding-top: 5px;
}

.order-view__row-contract-signed {
    padding-top: 15px;
}

.order-view__row-position {
    padding-top: 16px !important;
}


.order-view__position-title {
    color: #4A4A4A;
    font-weight: bold;
    padding: 5px 0;
}

.order-view__position-shipping-detail {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10px; 
}

.order-view__position-shipping-detail-service {
    flex: auto;
}

.order-view__position-shipping-detail-info {
    text-align: right;
}

.order-view__position-shipping-detail-info > div {
    padding: 2px 0;
}

.order-view__position-toggle {
    color: #0066A1;
}

.order-view__buttons-block {
    display: inline-block;
}

.order-view__buttons-block-flex {
    display: inline-flex;
}

.order-view__buttons-block-l {
    padding-right: 0 !important;
}

.order-view__buttons-block-r {
    padding-left: 12px !important;
}

.order-view__buttons-block-flex-select {
    min-width: 175px;
    max-width: 175px;
}


.position-table {
    margin: 10px 15px;
}

.position-table__row > td {
    padding: 15px 10px;
}


.position-table__header > th {
    padding: 10px 10px;
    color: #979797;
    font-weight: normal;
}


.position-table__detail {
    padding: 10px;
}

.position-table__detail td {
    padding: 2px 30px 0 0;
}

.position-table__detail-total {
    font-size: 16px;
    font-weight: bold;
}

.position-table__detail-total-sum {
    color: #E21A1A;
}

.order-status__color_grey {
    color: gray;
}

.position-element__row {
    display: flex;
    flex-direction: row;
}

.position-element__row > div {
    padding: 5px 5px 0 0;
}

.section__divider {
    border-bottom: 2px solid #00507C;
    padding: 5px 0;
}

.order-view__panel_created .order-view__head_menu {
    min-height: 50px;
    margin-top: 10px;
    position: relative;
}
