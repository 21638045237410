.bankGuaranteeCalc {
    border: 3px solid #0066A1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 25px 29px;
}

.bankGuaranteeCalc h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 114%;
    color: #0066A1;
}

.bankGuaranteeCalc .muted-text, .bankGuaranteeCalc_input-error {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #979797;
    text-align: center;
    margin-top: 10px;
}

.bankGuaranteeCalc_input-error {
    color: #E21A1A;
    text-align: left;
    font-size: 9px;
    margin-top: 5px;
}

.bankGuaranteeCalc .btn-primary {
    background-color: #0066A1;
}

.bankGuaranteeCalc__result-result-p {
    margin-top: 7px;
}

.bankGuaranteeCalc .bankGuaranteeCalc__result,
.bankGuaranteeCalc .bankGuaranteeCalc__result-red {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
}

.bankGuaranteeCalc .bankGuaranteeCalc__result-red {
    color: #E21A1A;
}
