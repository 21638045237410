.preview {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 200px;
    flex-direction: column;
    align-items: center;
}

.preview__img-block {
    max-width: 300px;
    max-height: 200px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
}

.preview__img {
    width: 100%;
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
}
.preview__img_small {
    max-height: 56px;
    max-width: 56px;
}
.preview__img_large {
    max-height: 200px;
    max-width: 300px;
}
.preview-area img {
    border-radius: 8px;
    overflow: hidden;
}

.position-element__img-wrap .mb8 {
    margin-bottom: 8px !important;
}
.preview-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.65);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: default;
}

.preview-modal__body {
    padding: 0;
    margin-top: -50px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 180px)
}

.preview-modal__prev, .preview-modal__next {
    top: 0;
    height: 100%;
    position: absolute;
    width: 100px;
}
.preview-modal__prev-pos, .preview-modal__next-pos {
    position: absolute;
    height: 100%;
    align-items: center;
}
.preview-modal__prev {left: 0;}
.preview-modal__prev-pos {right: 20px;}

.preview-modal__next {right: 0;}
.preview-modal__next-pos {left: 20px;}

.preview-modal__prev-block, .preview-modal__next-block {
    border: 20px solid rgba(0,0,0,0.01);
    background: rgba(0,0,0,0.25);
    border-radius: 10px;
    transition: all 0.15s cubic-bezier(.25, .8, .25, 1);
}
.preview-modal__prev-block:hover, .preview-modal__next-block:hover {
    border: 20px solid rgba(0,0,0,0.02);
    background: rgba(0,0,0,0.35);
    transition: all 0.15s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.preview-modal__flex {
    height: 100%;
    display: flex;
    align-items: center;
}

.preview-modal__close_flex {
    display: flex;
    align-items: center;
    float: right;
    right: 15px;
    position: absolute;
    z-index: 9999;
}
.preview-modal__close-block {
    border: 20px solid rgba(0,0,0,0.01);
    background: rgba(0,0,0,0.25);
    border-radius: 50%;
    height: 60px;
    transition: all 0.15s cubic-bezier(.25, .8, .25, 1);
}
.preview-modal__close-block:hover {
    border: 20px solid rgba(0,0,0,0.02);
    background: rgba(0,0,0,0.35);
    border-radius: 50%;
    height: 60px;
    transition: all 0.15s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.preview-modal__picture {
    max-height: calc(100vh - 200px);
    max-width: calc(100vw - 200px);
}

.preview-modal__picture-prev, .preview-modal__picture-next {
    max-height: calc(70vh - 100px);
    opacity: 0.65;
}

.preview-modal__picture-prev:hover, .preview-modal__picture-next:hover {
    max-height: calc(70vh - 100px);
    cursor: pointer;
    opacity: 0.9;
}

.preview-modal__picture-gallery {
    display: inline-flex;
    cursor: pointer;
    border-radius: 6px;
    max-height: 120px;
    height: max-content;
    max-width: 120px;
}

.preview-modal__gallery_inner {
    display: flex;
    justify-content: center;
    width: 120px;
    align-items: center;
}
.preview-modal-selected {
    border: 2px solid #1b809e;
    padding: 5px 5px;
    border-radius: 6px;
    max-height: 120px;
}

.preview-modal__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    text-align: center;
    background: #000;
}

.preview-modal__title {
    display: inline-block;
    margin-top: 5px;
    color: #fff;
}

.preview-modal__close {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.453557 0.453557C0.55806 0.34879 0.682205 0.265668 0.818882 0.208954C0.955558 0.152239 1.10208 0.123047 1.25006 0.123047C1.39803 0.123047 1.54456 0.152239 1.68123 0.208954C1.81791 0.265668 1.94205 0.34879 2.04656 0.453557L8.00006 6.40931L13.9536 0.453557C14.0582 0.348959 14.1823 0.265988 14.319 0.20938C14.4557 0.152772 14.6021 0.123636 14.7501 0.123636C14.898 0.123636 15.0445 0.152772 15.1811 0.20938C15.3178 0.265988 15.442 0.348959 15.5466 0.453557C15.6512 0.558155 15.7341 0.682331 15.7907 0.818994C15.8473 0.955658 15.8765 1.10213 15.8765 1.25006C15.8765 1.39798 15.8473 1.54446 15.7907 1.68112C15.7341 1.81778 15.6512 1.94196 15.5466 2.04656L9.59081 8.00006L15.5466 13.9536C15.6512 14.0582 15.7341 14.1823 15.7907 14.319C15.8473 14.4557 15.8765 14.6021 15.8765 14.7501C15.8765 14.898 15.8473 15.0445 15.7907 15.1811C15.7341 15.3178 15.6512 15.442 15.5466 15.5466C15.442 15.6512 15.3178 15.7341 15.1811 15.7907C15.0445 15.8473 14.898 15.8765 14.7501 15.8765C14.6021 15.8765 14.4557 15.8473 14.319 15.7907C14.1823 15.7341 14.0582 15.6512 13.9536 15.5466L8.00006 9.59081L2.04656 15.5466C1.94196 15.6512 1.81778 15.7341 1.68112 15.7907C1.54446 15.8473 1.39798 15.8765 1.25006 15.8765C1.10213 15.8765 0.955658 15.8473 0.818994 15.7907C0.682331 15.7341 0.558155 15.6512 0.453557 15.5466C0.348959 15.442 0.265988 15.3178 0.20938 15.1811C0.152772 15.0445 0.123636 14.898 0.123636 14.7501C0.123636 14.6021 0.152772 14.4557 0.20938 14.319C0.265988 14.1823 0.348959 14.0582 0.453557 13.9536L6.40931 8.00006L0.453557 2.04656C0.34879 1.94205 0.265668 1.81791 0.208954 1.68123C0.152239 1.54456 0.123047 1.39803 0.123047 1.25006C0.123047 1.10208 0.152239 0.955558 0.208954 0.818882C0.265668 0.682205 0.34879 0.55806 0.453557 0.453557Z' fill='white'/%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    border-radius: 50%;
}

.preview-next {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.968997 0.468876C1.03867 0.399031 1.12143 0.343617 1.21255 0.305807C1.30366 0.267997 1.40135 0.248535 1.5 0.248535C1.59865 0.248535 1.69633 0.267997 1.78745 0.305807C1.87857 0.343617 1.96133 0.399031 2.031 0.468876L11.031 9.46888C11.1008 9.53854 11.1563 9.62131 11.1941 9.71243C11.2319 9.80354 11.2513 9.90122 11.2513 9.99988C11.2513 10.0985 11.2319 10.1962 11.1941 10.2873C11.1563 10.3784 11.1008 10.4612 11.031 10.5309L2.031 19.5309C1.89017 19.6717 1.69916 19.7508 1.5 19.7508C1.30083 19.7508 1.10983 19.6717 0.968997 19.5309C0.828168 19.39 0.74905 19.199 0.74905 18.9999C0.74905 18.8007 0.828168 18.6097 0.968997 18.4689L9.4395 9.99988L0.968997 1.53088C0.899153 1.46121 0.843738 1.37844 0.805928 1.28733C0.768119 1.19621 0.748657 1.09853 0.748657 0.999876C0.748657 0.901225 0.768119 0.803543 0.805928 0.712426C0.843738 0.621308 0.899153 0.538544 0.968997 0.468876Z' fill='white'/%3E %3C/svg%3E");
    width: 20px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    margin-top: 5px;
}

.preview-prev {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.031 0.468876C11.1008 0.538544 11.1563 0.621308 11.1941 0.712426C11.2319 0.803543 11.2513 0.901225 11.2513 0.999876C11.2513 1.09853 11.2319 1.19621 11.1941 1.28733C11.1563 1.37844 11.1008 1.46121 11.031 1.53088L2.5605 9.99988L11.031 18.4689C11.1718 18.6097 11.2509 18.8007 11.2509 18.9999C11.2509 19.199 11.1718 19.39 11.031 19.5309C10.8902 19.6717 10.6992 19.7508 10.5 19.7508C10.3008 19.7508 10.1098 19.6717 9.969 19.5309L0.968997 10.5309C0.899153 10.4612 0.843738 10.3784 0.805928 10.2873C0.768119 10.1962 0.748657 10.0985 0.748657 9.99988C0.748657 9.90122 0.768119 9.80354 0.805928 9.71243C0.843738 9.62131 0.899153 9.53854 0.968997 9.46888L9.969 0.468876C10.0387 0.399031 10.1214 0.343617 10.2125 0.305807C10.3037 0.267997 10.4013 0.248535 10.5 0.248535C10.5986 0.248535 10.6963 0.267997 10.7874 0.305807C10.8786 0.343617 10.9613 0.399031 11.031 0.468876Z' fill='white'/%3E %3C/svg%3E");
    width: 20px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    margin-top: 3px;
}

.preview-modal__gallery {
    position: absolute;
    top: calc(100% - 150px);
    left: 0;
    gap: 20px;
    height: 150px;
    width: 100%;
    background: rgba(0,0,0,0.25);
    display: inline-flex;
    padding-top: 15px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10 IE11 */
    .preview-modal__close {
        right: 15px;
    }
}
.preview__indicator-block {
    width: 300px;
    position: absolute;
    height: 2px;
    z-index: 5;
    gap: 5px;
    display: inline-flex;
    border-radius: 44px;
    margin-top: 150px;
    align-items: center;
    justify-content: center;
}
.preview__indicator-item {
    background: rgba(255,255,255,0.5);
    width: 17px;
    max-width: 17px;
    height: 2px;
    z-index: 5;
}
.preview__indicator-item-active {
    background: rgba(255,255,255,0.9);
}