.contract-wrap{
    font-size: 16px;
}

.contract-wrap h3 a{
    font-size: 14px;
}

.contract-card {
    margin-bottom: 30px;
}

.contract-card span {
    color: #6C6C6C;
}

.info-wrap {
    margin-bottom: 20px;
}

.project-wrap{
    margin-bottom: 20px;
}

.project-block, .contract-block-wrap{
    padding: 0 0 20px 20px;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
}

.project-item{
    margin-bottom: 10px;
    padding-top: 10px;
    word-break: break-all;
}

.project-item span{
    color: #979797;
}

.project-item i{
    color: #4A4A4A;
    cursor: pointer;
}

.btn-contract-wrap{
    text-align: center;
}

.btn-contract-wrap button, .btn-contract-wrap a {
    margin-left: 20px;
    font-size: 14px;
}

.btn-contract-wrap button:first-child {
    margin-left: 0;
}

.signature-wrap {
    font-size: 12px;
}

.signature-item {
    width: calc(100% - 5px);

    height: 44px;
    float: right;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    margin-bottom: 10px;
}

.project-line .signature-item {
    border-top: none;
}

.project-line ~ .project-line .signature-item {
    border: 1px solid #DDDDDD;
}

.project-line .signature-wrap .row .col-sm-6 ~ .col-sm-6 .signature-item {
    border-top: none;
    border-right: none;
}

.project-line ~ .project-line .signature-wrap .row .col-sm-6 ~ .col-sm-6 .signature-item {
    border-top: 1px solid #DDDDDD;
    border-right: none;
}

.signature-icon-wrap {
    border-right: 1px solid #DDDDDD;
    width: 44px;
    height: 44px;
    border-radius: 3px;
    font-size: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    float: left;
}

.signature-icon-wrap i {
    color: #3EB659;
    font-size: 24px;
}
.mchd-sign-icon a {
    font-size: 19px;
}
.mchd-sign-icon a.valid {
    color: #006742;
}
.mchd-sign-icon a.expired {
    color: #CD5D00;
}
.mchd-sign-icon a.revoked, .mchd-sign-icon a.terminated {
    color: #9E000F;
}
.mchd-sign-icon a.notValid, .mchd-sign-icon a.notvalid {
    color: #495057;
}
.mchd-sign-icon a.undefined {
    color: #D5DEE5;
}

.signature-icon-wrap i.red {
    color: #E21A1A;
}


.signature-status {
    color: #4A4A4A;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 44px;
    padding: 0 10px;
}
.inactive {
    background: #F5F5F5;
}

.inactive .signature-icon-wrap i {
    color: #979797!important;
}

.contract-date span {
    color: #979797;
}

.status-wrap {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    font-size: 14px;
}

.hide-action-wrap {
    font-size: 14px;
    color: #337ab7!important;
}

.contract-status {
    float: right;
    margin-right: 20px;
}

.contract-green {
    color: #3DA124;
}

.contract-grey {
    color: #979797
}

.block-signed {
    padding-left: 20px;
    width: 100%;
}

.contract-download{
    padding-top: 10px;
    font-size: 13px;
}

.contract-download a{
    float: right;
    padding-left: 10px;
}

.contract-p-8 {
    padding-left: 8px;
}
.contract-input {
    padding-left: 0;
}
.contract-input-l {
    padding-left: 0;
    padding-right: 0;
    white-space: nowrap;
    margin-top:4px;
}
.contract-input-row {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
}
.contract-input-row-f {
    padding-top: 5px;
    margin-left: 0;
    margin-right: 0;
}
.alertpanel-info {
    margin-left: 0;
    margin-top: 10px
}
.alertpanel-info span{
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.alertpanel-info .alert-panel__body {
    width: calc(100% - 35px);
}
.text-right {
    text-align: right;
}