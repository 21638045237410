@import url('../fonts/Roboto.css');
@import url('../fonts/Ubuntu.css');
@import url('../fonts/Raleway.css');
@import url('../fonts/Bitter.css');
@import url('../fonts/Root.css');
/*bug styles*/
.navbar-nav > li > a {
    cursor: pointer;
}

.navbar-nav > li > a:hover {
    color: #E21A1A !important;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
    color: #E21A1A;
    /*border-bottom: 3px solid #d10e16;*/
    /*margin-bottom: -3px;*/
}

#header {
    /*margin-top: 30px;*/
    margin-bottom: 20px;
}

#header .input-group {
    max-width: 100%;
}

#logoCaption {
    font-size: 14px;
    font-weight: bold;
    float: left;
    margin-top: 13px;
    margin-left: 10px;
    color: #415c6e;
}

#logoImg {
    float: left;
}

#headerSearch {
    margin-top: 6px
}


/*StartPage*/
#startPage {
    font-size: 14px;
    /*margin-top: 107px;*/
}

#startPage span {
    font-size: 15px;
}

.phone {
    font-size: 24px;
    margin-left: 50px;
    line-height: 45px;
}

span.phoneTab {
    width: 60px;
    display: inline-block;
}

#startPage .col-md-12 {
    margin-bottom: 30px;
}

.titleLine {
    width: 80%;
    height: 15px;
    border-bottom: 1px solid #000;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.titleTxt {
    font-size: 20px !important;
    background-color: #fff;
    padding: 0 10px;
}

.greenTitle, .blueTitle {
    text-transform: uppercase;
}

.greenTitle {
    color: #a0aa70;
}

.blueTitle {
    color: #3374a8;
}

#startPage .banner {
    /*background: url(/ccwe/img/startPage/slider_2.jpg);*/
    /*height: 300px;*/
    /*background-size: cover;*/
    /*padding: 30px;*/
    /*color: white;*/
    /*font-size: 25px;*/
}

span.register {
    line-height: 50px;
    margin-right: 50px !important;
}

.registerBlock {
    background: #D2D3D5;
    margin: 0;
    text-align: center;
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
}

#startPage .registerBtn {
    border-radius: 15px !important;
}

.slide2 {
    background: url(/ccwe/img/startPage/slide1.jpg) center top;
    width: 1920px;
    /*position:absolute;*/
    margin-left: 50%;
    transform: translateX(-50%);
}

.slide7 {
    background: url(/ccwe/img/startPage/slide2.jpg) center top;
    width: 1920px;
    /*position:absolute;*/
    margin-left: 50%;
    transform: translateX(-50%);
}

.slideControls {
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 1px;
}

.slider-right-arrow, .slider-left-arrow {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    top: 300px;
    opacity: 0;
    cursor: pointer;
    z-index: 9999;
}

.slider-right-arrow {
    background: url(/img/startPage/slider_right_arrow.png) no-repeat left top;
    right: 20px;
}

.slider-left-arrow {
    background: url(/img/startPage/slider_left_arrow.png) no-repeat left top;
    left: 20px;
}

#sliderPage:hover .slider-right-arrow, #sliderPage:hover .slider-left-arrow {
    opacity: 0.6 !important;
}

#startPage .banner6 {
    background: url(/img/startPage/pict1.jpg);
    height: 320px;
    background-size: cover;
}

#startPage .bannerDoc {
    background: url(/img/startPage/pictDoc.jpg);
    height: 80px;
    background-size: cover;
    padding: 10px;
    font-size: 14px;
}

.paddingLeft0 {
    padding-left: 0;
    background: #e6e6e6;
}

.padding-left-10 {
    padding-left: 10px;
}

.carousel-control-bottom10 {
    bottom: 10px !important;
}

.navbar-brand {
    padding: 0 15px !important;
    height: 43px;
}

.navbar-brand img {
    /* height: 25px; */
    top: -12px;
    position: relative;
}

.navbar-padding-bottom-80 {
    padding-bottom: 80px;
}

.navbar-margin-bottom-80 {
    /*margin-bottom: 120px;*/
}

.logo {
    float: left;
    width: 194px;
    height: 43px;
}

/* CSS used here will be applied after bootstrap.css */

/* Create a medium height at 40px */
.navbar-xl {
    min-height: 60px
}

.navbar-xl .navbar-brand,
.navbar-xl .navbar-nav > li > a {
    padding-top: 18px;
    padding-bottom: 18px
}

.navbar-xl .navbar-brand {
    height: 75px
}

.navbar-xl .navbar-toggle {
    margin: 11px 22px 12px 0px;
    padding: 12px 13px 12px 13px;
}

.navbar-xl .navbar-toggle .icon-bar {
    width: 35px;
}

.navbar-md {
    min-height: 40px
}

.navbar-md .navbar-brand,
.navbar-md .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px
}

.navbar-md .navbar-brand {
    height: 40px
}

.navbar-md .navbar-toggle {
    margin: 6px 12px 6px 0px;
    padding: 6px 7px 6px 7px;
}

.navbar-md .navbar-toggle .icon-bar {
    width: 19px;
}

/* Create a small height at 30px */
.navbar-sm {
    min-height: 30px
}

.navbar-sm .navbar-brand,
.navbar-sm .navbar-nav > li > a {
    padding-top: 5px;
    padding-bottom: 5px
}

.navbar-sm .navbar-brand {
    height: 30px
}

.navbar-sm .navbar-toggle {
    margin: 3px 9px 3px 0px;
    padding: 4px 4px 4px 4px;
}

.navbar-sm .navbar-toggle .icon-bar {
    width: 16px;
}

.nav-tabs {
    margin: 40px 0 20px 0;
}

.nav-tabs.profileTabs li {
    cursor: pointer;
}

#login-form {
    position: absolute;
    width: 245px;
    height: auto;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    left: -200px;
    background: white;
    padding: 20px 15px 0 15px;
    z-index: 100;
}

#login-form .form-group {
    padding-left: 8px;
    padding-right: 7px;
}

#login-form .form-control {
    font-size: 11px;
    line-height: 13px;
    height: 30px;
    border: 0.771477px solid #868686;
    border-radius: 2.31443px;
}

#login-form .btn {
    font-size: 11px;
    line-height: 13px;
    height: 30px;
    color: black;
}

#login-btn-form, #exit-btn, .login-btn-form {
    font-family: Verdana;
    padding: 7px 0 0 0;
    margin-top: 10px;
    margin-bottom: 2px;
    color: #E21A1A;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: none
}

#login-btn, .login-btn {
    font-family: Verdana;
    padding: 0 0 0 0;
    margin-top: 0;
    margin-bottom: 2px;
    color: #E21A1A;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: none
}

#login-btn-tablet {
    font-family: Verdana;
    padding: 8px 0 0 0;
    color: #E21A1A;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    border-bottom: none;
    width: 47px;
}

#crosslogin-form {
    width: 350px;
    height: auto;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    background: white;
    padding: 20px 17px 5px 17px;
    z-index: 100;
}

#crosslogin-form .form-group {
    padding-left: 8px;
    padding-right: 7px;
}

#crosslogin-form .form-control {
    font-size: 11px;
    line-height: 13px;
    height: 30px;
    border: 0.771477px solid #868686;
    border-radius: 2.31443px;
}

#crosslogin-form .btn {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    width: 200px;
    height: 30px;
}

.tabletExit {
    cursor: pointer;
    font-family: Verdana;
    padding: 7px 0 0 0;
    margin-top: 10px;
    margin-bottom: 2px;
    color: #0f0f0f;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: none
}

#all-news {
    text-decoration: underline;
    color: #4a4a4a;
}

.ad {
    text-align: center;
}

#login-btn.focus, #login-btn:focus,
#login-btn-form.focus, #login-btn-form:focus,
#login-btn-tablet.focus, #login-btn-tablet:focus,
.tabletExit.focus, .tabletExit:focus {
    color: #E21A1A;
    border-bottom: 2px solid #E21A1A;
    text-decoration: none !important;
    margin-bottom: 0px;
}

#login-btn:hover,
#login-btn-form:hover,
#login-btn-tablet:hover,
.tabletExit:hover {
    color: #E21A1A;
    border-bottom: 2px solid #E21A1A;
    text-decoration: none !important;
    margin-bottom: 0px;
}

#login-dp .help-block {
    font-size: 12px
}

#login-dp .bottom {
    background-color: rgba(255, 255, 255, .8);
    border-top: 1px solid #ddd;
    clear: both;
    padding: 14px;
}

#login-dp .social-buttons {
    margin: 12px 0
}

#login-dp .social-buttons a {
    width: 49%;
}

#login-dp .form-group {
    margin-bottom: 10px;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 165px;
    background-color: #E21A1A;
    padding: 30px 0;
    z-index: 2;
}

.footer-copy {
    font-size: 12px;
    color: #ffffff;
}

.footerLinks {
    margin-bottom: 15px;
}

.footerLinks ul {
    padding: 0;
}

.footerLinks li {
    display: block;
    margin-bottom: 10px;
    line-height: 15px;
}

.footerLinks a {
    font-size: 12px;
    color: #ffffff;
    display: inline-block;
}

.main-content {
    padding-bottom: 65px;
}

.lkRow {
    position: relative;
}

.lkMenu {
    position: absolute;
    right: 0;
    padding: 5px 5px 0 0;
}

.lkMenuOpened {
    margin: 0;
    padding: 5px 5px 20px 25px;
    background: #FFF;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
}

@media (min-width: 576px) and (max-width: 991px) {
    .lkMenuOpenLogo {
        display: none;
    }
}

.lkMenu-icon {
    margin-right: 5px;
}

.lk-menu {
    float: left;
    padding: 20px 25px 0 0;
    margin: 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
}

.lk-menu li {
    cursor: pointer;
    margin-top: 10px;
}

.lk-menu li:first-child {
    margin-top: 0;
}

.lk-menu > li > a {
    display: block;
    clear: both;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    white-space: nowrap;
    padding-left: 24px;
    position: relative;
    color: #000;
}

.lk-menu.is-operator-or-support > li > a {
    padding-left: 0;
}

.lk-menu > li > a .lkMenu-icon {
    position: absolute;
    left: 0;
    top: 0;
}

.lk-menu > li > a .lkMenu-icon-profile {
    left: 2px;
}

.lk-menu > li > a .lkMenu-icon-tariffs {
    left: 3px;
}

.lk-menu > li > a .lkMenu-icon-lists {
    left: 2px;
    font-size: 17px;
}

.lk-menu .dropdown-item:hover .dropdown-item-menu {
    display: block;
    position: relative;
    background: transparent;
    font-size: 12px;
    line-height: 15px;
    box-shadow: none;
    padding-left: 10px;
}

.lk-menu .dropdown-item-menu a{
    display: block;
    color: #000;
    margin-top: 10px;
}

.lk-menu .dropdown-item:hover > a{
    color: #333!important;
    background: transparent!important;
}

.title-lk-info-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #4B4B4B;
}

.small-lk-info-text {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #4B4B4B;
}

.tariff-funds-info-table .tr-lk-info td{
    padding: 0 30px 15px 0;
}

.tariff-funds-info-table .tr-lk-info-cost td{
    padding: 0 20px 0 0;
}

.lk-info-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000;
}
.tariff-funds-info-table tr td{
    padding-right: 10px;
}

header-msg {
    display: block;
    margin-top: 135px;
    top: 135px;
    z-index: 99;
}

cabinet {
    position: fixed;
    right: 0;
    left: 0;
    background: #ffffff;
    z-index: 1000;
    top: 140px;
}

cabinet p {
    margin-top: 125px;
}

cabinet a {
    color: #000000;
}

.userName {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    float: right;
    color: #E21A1A !important;
    padding: 0;
}

.export-excel-button {
    float: right;
    margin-top: -45px;
}

export-excel-a {
    margin-left: 5px;
    margin-right: 10px
}

.become-customer-card-title {
    background: #0066A1;
}

.contact-title {
    color: #E21A1A;
}

.dropdown-menu {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border-color: #ffffff;
}
.tariffs-reg-btn {
    height: 46px;
    font-size: 18px;
    text-align: center;
    color: #fff;
    background: #0066A1;
    border: 2px solid #0066A1;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-family: 'Verdana';
    font-style: normal;
    padding-right: 25px;
    padding-left: 25px;
}

.container>.navbar-header {
    margin-left: 0;
    margin-right: 0;
}

@media screen and (max-width: 991.98px) {
    .mob-hide {
        display: none;
    }
    .mob-btns-div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .mob-menu-single-toggler {
        margin-right: 5px;
    }
    .mob-menu-toggler {
        margin-right: 15px;
    }
    .mob-entry-btn, .mob-entry-btn:active, .mob-entry-btn:focus, .mob-entry-btn:hover {
        display: inline-block;
        color: white;
        background-color: #E21A1A;
        border: 1px solid transparent;
        padding: 9px 10px;
        margin-top: 8px;
        margin-bottom: 8px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        width: 45px;
        height: 35px;
    }
    .mob-entry-icon {
        color: white;
        margin-top: -7px;
        font-size: 10px;
    }
    .choose-bank-btn {
        display: flex;
        justify-content: center;
    }
    .desktop-doc {
        display: none !important;
    }
    .lk-menu > li > a {
        display: block;
        clear: both;
        font-weight: normal;
        font-size: 18px;
        white-space: nowrap;
        padding-left: 24px;
        position: relative;
        color: #000;
    }
    .lk-menu .dropdown-item:hover .dropdown-item-menu {
        display: block;
        position: relative;
        background: transparent;
        font-size: 16px;
        line-height: 26px;
        box-shadow: none;
        padding-left: 10px;
    }
    .mob-date-col {
        width: 150px;
    }
}
@media (min-width: 992px) {
    .mob-doc {
        display: none !important;
    }
    .choose-bank-btn {
        display: flex;
        justify-content: start;
    }
    .mob-btns-div {
        display: inherit;
    }
    .mob-entry-btn {
        display: none;
    }
}
.bg-row-flex {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    flex-wrap: wrap;
    gap: 1.4rem;
}