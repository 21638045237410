.news-block {
    color: #000;
}

.news-block__header {
    padding: 0;
    margin: 0;
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
}

.news-block__body {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 0;
}

.news-block__date {
    margin: 0;
}

.news-block__date-text {
    color: #888;
    font-size: 13px;
}

.news-block__attachments {
    display: inline-block;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 0;
}

.news-block__attachment {
    font-size: 13px;
}

.news-block__attachment-size {
    color: #888;
    padding-left: 5px;
}

/*Item*/
.news-block__item-vanisher { display: none; }

/*Модификаторы*/
.news-block--item {
    position: relative;
    padding: 15px;
}
.news-block--item:not(.news-block--selected) {
    cursor: pointer;
}
.news-block--item:not(.news-block--selected) .news-block__header {
    color: #337ab7;
}
.news-block--item:not(.news-block--selected):hover {
    background: rgba(0, 127, 177, 0.07);
}
.news-block--item:not(.news-block--selected):hover .news-block__header-text {
    border-bottom: 1px dashed #337ab7;
}

.news-block--collapsed > .news-block__item-vanisher {
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 15px;
    left: 0;
}

.news-block--selected {
    padding-left: 12px;
    border-left: solid #337ab7 3px;
}