.a-fac-body-inner {
    color: #4d4d4d;
    font-size: 15px;
    font-family: Root-light, Arial, sans-serif;
    background: #fff;
    -webkit-font-smoothing: antialiased;
}
.a-fac {
    background-color: rgb(250, 245, 241);
}
.a-fac-container {
    width: 900px !important;
    margin: 0 auto;
    padding: 0 15px;
}
.a-fac__inner {
    padding-top: 64px;
    padding-bottom: 64px;
}
.a-fac__contentWrapper {
    display: flex;
    justify-content: center;
}
.a-fac__content {
    max-width: 402px;
    margin-right: 54px;
}
.a-fac__title {
    margin-top: 20px;
    font-size: 36px;
    line-height: 1.15;
}
.a-fac__desc {
    margin-top: 25px;
    font-size: 18px;
    line-height: 1.56;
}
.a-fac__info {
    text-indent: 25px;
}
.a-fac__cost-text {
    margin-top: 25px;
    font-size: 21px;
    line-height: 1.56;
}
.a-fac__remark-text {
    margin: 0;
    font-size: 12px;
    line-height: 1.0;
}
.a-fac__button {
    margin-top: 40px;
}
.a-fac-button-get-guarantee {
    display: inline-block;
    padding: 20px 17px;
    color: #fff;
    font-size: 2rem;
    line-height: 0;
    text-decoration: none;
    background-color: #ff5000;
    border-radius: 2px;
    -webkit-transition: opacity 200ms ease-in-out;
    -moz-transition: opacity 200ms ease-in-out;
    -o-transition: opacity 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}
.a-fac-button-get-guarantee:hover,
.a-fac-button-get-guarantee:link,
.a-fac-button-get-guarantee:visited,
.a-fac-button-get-guarantee:active {
    color: #fff;
    text-decoration: none;
}
.a-fac-button-get-guarantee:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    transition: all 200ms ease-in-out;
    border-radius: 3px;
}
.a-fac-advantages__inner {
    padding-top: 80px;
    padding-bottom: 40px;
}
.a-fac-advantages__inner2 {
    padding-top: 40px;
    padding-bottom: 40px;
}
.a-fac-section-title {
    margin: 0;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.14;
    text-align: center;
}
.a-fac-advantages__definition {
    margin: 0;
    font-size: 15px;
    line-height: 1.6;
}
.a-fac-scheme-container {
    box-sizing: border-box;
}
.a-fac-scheme {
    padding: 0;
    border-radius: 10px;
}
.a-fac-steps-section-container {
    padding-top: 40px;
    padding-bottom: 40px;
}
.a-fac-steps-container {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 40px;
}
.a-fac-step {
    display: flex;
    align-items: center;
}
.a-fac-stepNumber-container {
    font-size: 18px;
    line-height: 28px;
    background-color: rgb(250, 245, 241);
    border-radius: 8px;
    padding: 10px;
}
.a-fac-stepContent {
    font-size: 18px;
    line-height: 1.6;
    padding-left: 25px;
}
.a-fac-categories {

}
.a-fac-categories__items {
    max-width: 914px;
    margin: 60px -24px 0;
    list-style: none;
}
.a-fac-category-item {
    position: relative;
    margin-bottom: 16px;
    padding-left: 24px;
    font-size: 15px;
    line-height: 1.6;
}
.a-fac-category-item:before {
    position: absolute;
    top: 8px;
    left: 0;
    width: 8px;
    height: 8px;
    background-color: #ff0000;
    border-radius: 50%;
    content: "";
}
.a-fac__bot {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.a-fac-center {
    text-align: center;
}