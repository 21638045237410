.news__header-title {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    font-weight: bold;
}

.news__header-filter-cell {
    display: table-cell;
    vertical-align: middle;
    width: 60%;
}

.news__header-filter {
    display: block;
    height: 34px;
}

.news__header-sub {
    padding: 10px 15px;
}

.news__header-sub-text {
    display: table-cell;
    margin: 0;
    font-weight: bold;
    color: #69655d;
    width: 100%;
    vertical-align: middle;
    text-align: center;
}

.news__header-sub-btn {
    display: table-cell;
    min-width: 364px;
}

.news__back {
    font-size: 13px;
}

.news__block-cover {
    margin-left: -15px;
    margin-right: -15px;
}