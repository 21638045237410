.services_block {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 20px 20px 20px;
    margin-bottom: 20px;
    background: #FDFDFD;
}

.services_block__title {
    font-size: 16px;
    line-height: 19px;
    color: #007FB1;
    font-weight: bold;
    margin-bottom: 10px;
}
.services_block__title__icon {
    font-size: 14px;
    margin-left: 5px;
    top: -1px;
    position: relative;
}

.services_block__link {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
}

.services_block__link__icon {
    padding-right: 5px;
    font-size: 20px;
    top: 2px;
    position: relative;
}
.services_block__link__icon:hover {
    text-decoration: none !important;
}
.services_block__text {
    margin: 10px 0;
}
.services_block__subtext {
    margin-top: 20px;
}
.services_block__subscribe_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 25px 0 10px 0;
}

.services_block__subscribe_btn .btn {
    margin: 0 5px;
}
.services_block .detail-btn-wrap {
    margin: 10px 0;
}
.services__price_info {
    color: gray;
}
.services_tariffs_info {
    display: flex;
    justify-content: stretch;
    align-items: normal;
    flex-direction: row;
    margin-bottom: 10px;
}
.services_tariffs_info-fe {
    display: flex;
    justify-content: flex-end;
    align-items: normal;
    flex-direction: row;
    margin-bottom: 10px;
    padding:0;
}
.add_teriff_btn {
    width: 200px
}