.bankRaiffeisen h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 100%;
}

.bankRaiffeisen .hero-text {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 114%;
}

.bankRaiffeisen .financialService_p-bullet p {
    line-height: 114%;
    margin-top: 25px;
}

.bankRaiffeisen__aboutText-header {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 114%;
}

.bankRaiffeisen__card {
    padding: 20px;
    min-height: 210px;
}

.bankRaiffeisen__card-last {
    margin-right: 0 !important;
}

.bankRaiffeisen__aboutText, .bankRaiffeisen__card-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 114%;
}

.bankRaiffeisen__card-header {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 114%;
}

.bankRaiffeisen__supportText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 114%;
    color: #979797;
}

.feedback-form-show-bankRaiffeisen {
    text-align: center;
    font-style: normal;
    font-size: 18px;
    line-height: 114%;
    color: #0066A1;
    margin-bottom: 20px;
}

.feedback-form-show-bankRaiffeisen a {
    border-bottom:#0066A1 2px dashed;
    color: #0066A1;
}

.feedback-form-show-bankRaiffeisen a:hover {
    text-decoration: none;
    border-bottom:#0077B9 2px dashed;
    color: #0077B9;
}

.feedback-form-bankRaiffeisen {
    margin-bottom: 20px;
}
