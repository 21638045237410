.home-news-block {
    font-family: Verdana, sans-serif;
    line-height: 1.25;
    font-size: 14px;
    word-wrap: break-word;
}

.home-news-block__header, .home-news-block__header h3 {
    font-weight: bold;
    color: #4A4A4A;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.25;
    cursor: pointer;
}

.home-news-block:first-child h3 {
    margin-top: 0;
}

.home-news-block__header-date {
    color: #9B9B9B;
    font-weight: normal;
    display: block;
    /*margin-top: 3px;*/
    font-size: 12px;
}

.home-news-block__body {
    color: #666;
    margin-bottom: 6px;
}

.home-news-block__body > p {
    margin-bottom: 0; /*!*/
}

.home-news-block__link {
    color: #E21A1A;
    font-weight: bold;
}

.home-news-block__link:hover {
    color: #E21A1A;
    border-bottom: 1px #E21A1A solid;
    text-decoration: none;
}