.position-element {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-bottom: 20px;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    padding: 15px 15px 10px 15px;
}


.position-element--best .position-element {
    border: 3px solid #007FB1;
    padding: 13px 13px 8px 13px;
}

.position-element__buttons-l {
    direction: ltr;
}

.position-element__button{
    display: inline-block;
}

.position-element--best .offers-compare__part-value.offers-compare__total-price {
    color: #008000;
}

.position-element--best + div>.offers-compare__variant-list {
    border: 3px #007fb1 solid !important;
    border-top: none !important;
}

.position-element__title {
    margin-bottom: 12px;
    line-height: 17px;
    font-family: 'OpenSans';
    font-weight: 400;
    color: #6D8796;
}
.position-btn {
    font-size: 16px;
}

.position-element__offerNumber {
    cursor: pointer;
    text-decoration: underline;
    font-family: 'RussianRail';
    font-weight: normal;
    color: #0066a1;
}

.position-element__offerNumber:hover {
    color: #0066a1;
}

.position-element__total-price {
    direction: ltr;
}

.position-element__header {
    color: #4A4A4A;
    font-size: 12px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 5px;
}

.position-element__details div, .position-element__details span {
    font-size: 12px;
}
.position-element__info div {
    font-family: OpenSans;
}
.position-element__details div .position-element__property {
    color: #7C7C7C;
}
.position-element__price-block {
    text-align: right;
    position: static;
}

.position-element__property {
    color: #000;
    font-size: 13px;
    padding-bottom: 5px;
}
.position-element__name {
    display: flex;
    padding-left: 20px;
    margin-bottom: 5px;
    width: fit-content;
}
.position-element__name:hover {
    background-color: #E5F5FF !important;
}
.position-element__name a:hover {
    text-decoration: none !important;
}

.position-element__value {
    color: #000;
}

.position-element__regions {
    margin-left: 0;
}

.position-element__params-preview {
    max-height: 85px;
    overflow: hidden;
}

.position-element__params {
    font-size: 14px;
    color: #00507C;
    margin-bottom: 0;
    display: flex;
    padding-right: 5px;
    cursor: pointer;
}

.position-element__params_toggle {
    cursor: pointer;
}

.position-element__supplier {
    display: inline-block;
    padding-top: 15px;
}

.position-element__supplier-title{
    font-size: 12px;
    line-height: 15px;
    color: #4A4A4A;
}

.position-element__discount {
    margin-top: -2px;
    margin-right: 4px;
}

.position-element__count {
    width: 100%;
    color: #4A4A4A;
    white-space: nowrap;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    height: 100%;
}

.position-element__count-editable {
    direction: ltr;
    white-space: nowrap;
}

.position-element__price {
    font-weight: bold;
    font-size: 14px;
    color: #4A4A4A;
    margin-right: 3px;
}

.position-element__cross {
    font-size: 10px;
    position: relative;
    top: -1px;
}

.position-element__price-btn-ico {
    margin-top: -2px;
}

.position-element__price-input {
    width: 101px;
    height: 26px;
    display: inline-block;
    margin-left: 5px;
    line-height: 10px;
    padding-top: 5px;
}

.position-element__price-text {
    font-size: 20px;
    position: relative;
    top: 2px;
    color: #4A4A4A;
    margin-left: 2px;
}

.position-element__price-btn {
    height: 26px;
    display: inline-block;
    margin-left: -8px;
    line-height: 10px;
    border-radius: 4px;
    padding-top: 3px;
    border: solid #ccc 1px;
    font-size: 12px;
    background: #fff;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.position-element__price-btn-ico {
    font-size: 14px;
}

.position-element__best {
    margin-top: -9px;
    margin-bottom: 5px;
    margin-right: 1px;
    color: #008000;
    font-size: 16px;
    transform: scale(-1, 1);
    text-align: left;
}

.position-element__okpd-title {
    color: #7C7C7C;
    font-family: OpenSans;
    font-size: 12px;
}
.position-region span {
    font-family: OpenSans;
    font-size: 14px;
}
.position-element__date_value {
    color: #000;
    font-family: OpenSans;
    font-size: 12px;
}
.position-date {
    display: inline-block;
    width: 17px;
}

.position-element__okpd {
    margin-bottom: 3px;
    font-size: 12px;
}

.position-element__discount-tip {
    color: #999;
    font-weight: normal;
}
.position-element__img-container{
    width: 300px;
    height: 200px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    row-gap: 8px;
}
.position-element__img-wrap{
    cursor: pointer;
    display: flex;
}
.position-element__img-first{
    width:300px;
    height: 200px;
    flex-grow: 0;
    justify-content: center;
}
.position-element__img {
    width:56px;
    height:56px;
    align-items: center;
    margin: 0 2px
}

.position-element__price-block .cost {
    font-size: 24px;
    color: #000;
    text-align: right;
    font-family: 'OpenSans';
    margin-bottom: 10px;
    flex: 1;
    direction: ltr;
}
.position-element__price-block .cost.nds  {
    font-size: 18px;
    line-height: 24.51px;
    margin-bottom: 0;
}
.cost-desc {
    font-family: 'OpenSans';
    font-size: 10px;
    line-height: 14px;
    color: #979797;
}

.position-element__price-block .acceptance{
    direction: initial;
}

.position-element__price-block .acceptance-date{
    margin-top: 5px;
}

.position-element__price-block .acceptance-date:first-child{
    margin-top: 0;
}

.position-element__price-block .acceptance span{
    color: #9B9B9B
}

.acceptance-title{
    margin-bottom: 5px;
    font-size: 12px;
}

.position-marker {
    color: #0066A1;
}
.acceptance div {
    padding-bottom: 4px;
}

.position-img {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='14' height='18' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.25 2.1875C12.4906 2.1875 12.6875 2.38437 12.6875 2.625V11.3695L12.5508 11.1918L8.83203 6.3793C8.70898 6.21797 8.51484 6.125 8.3125 6.125C8.11016 6.125 7.91875 6.21797 7.79297 6.3793L5.52344 9.31602L4.68945 8.14844C4.56641 7.97617 4.36953 7.875 4.15625 7.875C3.94297 7.875 3.74609 7.97617 3.62305 8.15117L1.43555 11.2137L1.3125 11.3832V11.375V2.625C1.3125 2.38437 1.50937 2.1875 1.75 2.1875H12.25ZM1.75 0.875C0.784766 0.875 0 1.65977 0 2.625V11.375C0 12.3402 0.784766 13.125 1.75 13.125H12.25C13.2152 13.125 14 12.3402 14 11.375V2.625C14 1.65977 13.2152 0.875 12.25 0.875H1.75ZM3.9375 6.125C4.10986 6.125 4.28053 6.09105 4.43977 6.02509C4.59901 5.95913 4.7437 5.86245 4.86558 5.74058C4.98745 5.6187 5.08413 5.47401 5.15009 5.31477C5.21605 5.15553 5.25 4.98486 5.25 4.8125C5.25 4.64014 5.21605 4.46947 5.15009 4.31023C5.08413 4.15099 4.98745 4.0063 4.86558 3.88442C4.7437 3.76255 4.59901 3.66587 4.43977 3.59991C4.28053 3.53395 4.10986 3.5 3.9375 3.5C3.76514 3.5 3.59447 3.53395 3.43523 3.59991C3.27599 3.66587 3.1313 3.76255 3.00942 3.88442C2.88755 4.0063 2.79087 4.15099 2.72491 4.31023C2.65895 4.46947 2.625 4.64014 2.625 4.8125C2.625 4.98486 2.65895 5.15553 2.72491 5.31477C2.79087 5.47401 2.88755 5.6187 3.00942 5.74058C3.1313 5.86245 3.27599 5.95913 3.43523 6.02509C3.59447 6.09105 3.76514 6.125 3.9375 6.125Z' fill='%2300507C'/%3E%3C/svg%3E");
    width: 16px;
    height: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: 14px 18px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 1px;
    min-width: 14px;
    cursor: pointer;
}

.position-img-closed {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E  %3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.484499 0.23456C0.519333 0.199638 0.560715 0.17193 0.606274 0.153026C0.651832 0.134121 0.700673 0.12439 0.749999 0.12439C0.799324 0.12439 0.848165 0.134121 0.893724 0.153026C0.939283 0.17193 0.980665 0.199638 1.0155 0.23456L5.5155 4.73456C5.55042 4.76939 5.57813 4.81078 5.59703 4.85633C5.61594 4.90189 5.62567 4.95073 5.62567 5.00006C5.62567 5.04939 5.61594 5.09823 5.59703 5.14378C5.57813 5.18934 5.55042 5.23073 5.5155 5.26556L1.0155 9.76556C0.945084 9.83597 0.849581 9.87553 0.749999 9.87553C0.650417 9.87553 0.554914 9.83597 0.484499 9.76556C0.414084 9.69514 0.374525 9.59964 0.374525 9.50006C0.374525 9.40048 0.414084 9.30497 0.484499 9.23456L4.71975 5.00006L0.484499 0.76556C0.449576 0.730726 0.421869 0.689344 0.402964 0.643785C0.384059 0.598226 0.374329 0.549385 0.374329 0.50006C0.374329 0.450735 0.384059 0.401894 0.402964 0.356335C0.421869 0.310776 0.449576 0.269394 0.484499 0.23456Z' fill='%2300507C'/%3E  %3C/svg%3E");
    width: 16px;
    height: 10px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 6px 10px;
    margin-top: 5px;
    margin-left: -15px;
    min-width: 16px;
}

.position-img-opened {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.7655 0.48456C9.80042 0.519394 9.82813 0.560776 9.84704 0.606335C9.86594 0.651893 9.87567 0.700734 9.87567 0.75006C9.87567 0.799385 9.86594 0.848226 9.84704 0.893785C9.82813 0.939344 9.80042 0.980726 9.7655 1.01556L5.2655 5.51556C5.23067 5.55048 5.18928 5.57819 5.14373 5.59709C5.09817 5.616 5.04933 5.62573 5 5.62573C4.95068 5.62573 4.90183 5.616 4.85628 5.59709C4.81072 5.57819 4.76934 5.55048 4.7345 5.51556L0.234501 1.01556C0.164086 0.945145 0.124528 0.849641 0.124528 0.750059C0.124528 0.650478 0.164086 0.554974 0.234501 0.484559C0.304916 0.414144 0.400419 0.374586 0.500001 0.374586C0.599583 0.374586 0.695086 0.414144 0.765501 0.484559L5 4.71981L9.2345 0.48456C9.26934 0.449637 9.31072 0.42193 9.35628 0.403025C9.40183 0.38412 9.45068 0.37439 9.5 0.37439C9.54933 0.37439 9.59817 0.38412 9.64373 0.403025C9.68929 0.42193 9.73067 0.449637 9.7655 0.48456Z' fill='%2300507C'/%3E%3C/svg%3E");
    width: 16px;
    height: 6px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 10px 6px;
    margin-top: 6px;
    margin-left: -15px;
    min-width: 16px;
}
.position-element__details {
    background-color: #FAFDFF;
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 0px;
}

.position-element-imgs {
    float: right;
}
.position-elemnent-btns {
    padding-top: 5px;
    display: flex;
    justify-content: flex-end;
}
.position-element__count .preview-modal__close:after {
    top: -8px !important;
    left: 3px !important;
}
.pos-name-view {
    width: 100%;
    display: flex;
    margin-top: -2px;
}
.offer-el-detail-has-img {
    height: 280px;
}
