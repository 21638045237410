@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/OpenSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/OpenSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

.offers-container {
    font-family: OpenSans;
    font-weight: 400;
}
.offers-container p {
    font-family: OpenSans;
    font-weight: 600;
}
.offers-container .form-group {
    margin-bottom: 8px
}

.offers-info label, .offers-info div {
    color: #4a4a4a;
    padding-left: 0;
}
.offers-container label {
    font-weight: 400;
}
.offers-container div {
    line-height: 21px;
}
.offers-info .form-group {
    margin-left: 0;
}
.offer-doc-position {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.offer-doc-position-open {
    padding:19px;
    display: block;
}

.offers_create__non-nds {
    font-size: 16px;
    padding-top: 4px;
}

.a-disabled, .a-disabled:hover {
    color: #777;
    cursor: not-allowed;
    text-decoration: none;
}
.control-label {
    font-weight: bold;
}


.position_section_divider {
    border-bottom: 2px solid #909CAA;
    margin-bottom: 20px;
}


.position_section_divider_blue {
    border-bottom: 2px solid #00507C !important;
}

.position_section_divider h4 {
    font-size: 14px;
}


.wellNew {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f7f7f7;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    max-width: 1146px;
}

.text-angular-offer .ta-scroll-window > .ta-bind, .text-angular-offer .ta-scroll-window.form-control{
    min-height: 200px;
}
.text-angular-offer .placeholder-text::before{
    color: #777
}

.offer-site{
    background: #E5F5FF;
    border-radius: 3px;
    padding: 2px 5px;
    font-family: OpenSans;
    color: #3C3E3E;
    font-size: 12px;
    margin-bottom: 10px;
}
.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #FDFDFD;
}
.table-striped>tbody>tr>td, .table-striped>tbody>tr>td>a {
    font-size: 14px;
}
.offer-view-details {
    background-color: #FDFDFD;
    padding: 5px 32px 5px 32px;
}
.offer-view-details td {
    /*border-top: 0 !important;*/
}
.td-no-border {
    border-top: 0 !important;
}
.offer-view-details-td {
    background-color: #fff;
    border-top: 1px solid #F6F6F6 !important;
    border-bottom: 1px solid #F6F6F6;
}
.ovd-l {
    border-left: 1px solid #F6F6F6;
}
.ovd-r {
    border-right: 1px solid #F6F6F6;
}
.cost {
    font-size: 16px;
    color: #000;
    line-height: 21.79px;
    text-align: right;
    font-family: 'OpenSans';
    margin-bottom: 10px;
    flex: 1;
    direction: ltr;
}
.cost.nds  {
    font-size: 16px;
    line-height: 24.51px;
    margin-bottom: 0;
}
.cost-desc {
    font-family: 'OpenSans';
    font-size: 10px;
    line-height: 14px;
    color: #979797;
}
.offer-view-details-key {
    color: #7C7C7C;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
}
.offer-view-details-value {
    color: #000;
    font-size: 12px;
}
.offer-view-details-col {
    display: inline-table;
    width: 240px;
}
.offers-info .procedure-line-title {
    border-bottom: 1px solid #F6F6F6;
}
.offer-view-title p {
    font-famity: OpenSans;
    font-size: 28px;
    font-weight: 400;
}
.offer-view-status {
    display: flex;
    align-items: center;
}

.offer-view-status-black {
    color: #000000 !important;
}
.offer-view-status-green {
    color: #135736 !important;
}
.offer-view-status-red {
    color: #e21a1a !important;
}

.offer-view-status-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z' fill='%23135736'/%3E%3C/svg%3E");
    width: 18px;
    height: 18px;
    display: block;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    margin-left: 10px;
}
.offer-view-tabs {
    margin: 24px 0 10px 0 !important;
}
.offer-add-to-cart-btn {
    white-space: nowrap !important;
    pointer-events: auto;
}
.offer-view-detail-has-img {
    height: 420px;
}
