.pb-25 {
    padding-bottom: 25px
}
.p-0 {
    padding:0;
}
.pl-0 {
    padding-left: 0;
}
.pr-0 {
    padding-right: 0;
}
.ta-center {
    text-align: center;
}
.wb {
    word-wrap:break-word
}
.mb-15 {
    margin-bottom: 15px;
}
.pl-15 {
    padding-left: 15px
}
.end-date {
    float: right;
}