.bcdiv {
    font-size: 46px;
    display: inline-block;
    margin-right: 15px;
    color: #0066A1;
    height: 87px;
    width: 100%;
}
.document-info {
    margin-left: 8px;
}
.document-title {
    font-family: Verdana;
    padding-bottom: 5px;
}
.document-type {
    font-family: Verdana;
    vertical-align:top;
}
.steps-item-wrap .bp-steps-item {
    padding: 20px 30px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background: #fff;
    font-size: 16px;
    float: left;
    width: 45%;
    margin-bottom: 20px;
    text-align: left;
}
.bp-reg-wrap {
    padding-top: 15px;
}
.bp-step-number {
    /*position: absolute;*/
    top: 0;
    left: calc(50% - 128px);
    height: 46px;
    /*margin-left: -128px;*/

    line-height: 42px;
    text-align: center;
    color: #0066A1;

    background: #FFFFFF;
    border: 2px solid #0066A1;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 30px;

    font-family: 'Verdana';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}
.bp-step-text {
    padding-left: 29px;
    padding-right: 29px;
}
.bp-info-block {
    background: #DDECF6;
    border: 1px solid #C5E6F0;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 15px 68px 10px 5px;
    margin-top: 20px;
}
.bp-simple-text, .bp-simple-text li {
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}
.bp-info-text {
    color: #426F8C;
}
.text-align-center {
    padding-bottom: 50px;
}
.become-participant-label {
    margin: 35px 0 35px;
    font-weight: bold;
    text-align: center;
}
.bp-title {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Verdana';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    font-weight: bold;
}
.bp-text {
    padding-bottom: 35px;
}

.bp-text li, bp-fine-text {
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
}
.bp-row {
    display: flex;
    flex-direction: row;
    padding-bottom: 21px;
}
.bp-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 21px;
}
.bp-finish-block {
    display: flex;
    justify-content: center;
    align-items: center;
}
.bp-after-finish {
    padding-top: 33px;
}
