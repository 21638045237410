.vi-st1 {
    font-weight: bold; font-size: 12px
}
.vi-st2 {
    width: 200px
}
.vi-st3 {
    padding-top: 10px
}
.vi-st4 {
    font-size: 12px
}
.vi-st5 {
    padding-top:25px
}
.vi-st6 {
    width: 100%;
}
.vi-st7 {
    color: #007fb1;
    font-weight: bold;
    font-size: 14px;
}
.vi-st8 {
    width: 12px;
}
.vi-st9 {
    color: #515151;
    font-size: 12pt;
}
.vi-st10 {
    color: #9b9b9b;
}
.vi-st11 {
    padding-top: 15px;
    color: black;
}
.vi-cutoff-text {
    --max-lines: 10;
    --line-height: 1.42857143;
    max-height: calc(var(--max-lines) * 1em * var(--line-height));
    line-height: var(--line-height);
    overflow: hidden;
    position: relative;
}
.vi-cutoff-text:before {
    content: "";
    position: absolute;
    height: calc(2em * var(--line-height));
    width: 100%;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(to bottom, transparent, white);
}