@font-face {
	font-family: 'Root-bold';
	src: url('Root_Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Root-medium';
	src: url('Root_Medium.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Root-light';
	src: url('Root_Light.woff2') format('woff2');
	font-weight: 200;
	font-style: normal;
}