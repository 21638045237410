.bankLoko h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 100%;
}

.bankLoko .hero-text {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 114%;
}

.bankLoko .financialService_p-bullet p {
    line-height: 114%;
    margin-top: 5px;
}

.bankLoko__aboutText-header {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 114%;
}

.bankLoko__card {
    padding: 20px;
}

.bankLoko__card-last {
    margin-right: 0 !important;
}

.bankLoko__aboutText, .bankLoko__card-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 114%;
}

.bankLoko__card-header {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 114%;
}

.bankLoko__supportText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 114%;
    color: #979797;
}

.feedback-form-show-bankLoko {
    text-align: center;
    font-style: normal;
    font-size: 18px;
    line-height: 114%;
    color: #0066A1;
    margin-bottom: 20px;
}

.feedback-form-show-bankLoko a {
    border-bottom:#0066A1 2px dashed;
    color: #0066A1;
}

.feedback-form-show-bankLoko a:hover {
    text-decoration: none;
    border-bottom:#0077B9 2px dashed;
    color: #0077B9;
}

.feedback-form-bankLoko {
    margin-bottom: 20px;
}

@media (max-width: 991px) {
    .bankLoko .financialService_p-bullet p {
        line-height: 114%;
        margin-top: 25px;
    }
}