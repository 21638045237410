.bankGuarantees h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 100%;
}

.bankGuarantees .hero-text {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 114%;
}

.bankGuarantees .financialService_p-bullet p {
    margin-top: 25px;
}

.bankGuarantees__aboutText-header {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 114%;
}

.bankGuarantees__card {
    padding: 20px;
    min-height: 210px;
}

.bankGuarantees__card-last {
    margin-right: 0 !important;
}

.bankGuarantees__aboutText, .bankGuarantees__card-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 114%;
}

.bankGuarantees__card-header {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 114%;
}

.bankGuarantees__supportText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 114%;
    color: #979797;
}

.feedback-form-show-bankGuarantees {
    text-align: center;
    font-style: normal;
    font-size: 18px;
    line-height: 114%;
    color: #0066A1;
    margin-bottom: 20px;
}

.feedback-form-show-bankGuarantees a {
    border-bottom:#0066A1 2px dashed;
    color: #0066A1;
}

.feedback-form-show-bankGuarantees a:hover {
    text-decoration: none;
    border-bottom:#0077B9 2px dashed;
    color: #0077B9;
}

.feedback-form-bankGuarantees {
    margin-bottom: 20px;
}
