/* Проверка компании */
.companyValidate__title {
    line-height: 22px;
    font-size: 18px;
    color: #333333;
}
.companyValidate__ratingBlock {
    margin-top: 5px;
    margin-bottom: 10px;
}
.companyValidate__rating{
    margin-right: 15px;
}
.companyValidate__status{
    line-height: 16px;
    font-size: 14px;
}
.companyValidate__smp {
    font-size: 12px;
    color: #F5F5F5;
    text-align: center;
    background: #E21A1A;
    width: 44px;
    height: 18px;
    line-height: 18px;
    margin-left: 15px;
    margin-top: 3px;
    display: inline-block;
}
.companyValidate__subtitle {
    line-height: 17px;
    font-size: 14px;
    color: #9B9B9B;
    margin-bottom: 1px;
}
.companyValidate__text {
    line-height: 16px;
    font-size: 16px;
    color:#333333;
}
.companyValidate__textBlock {
    float: left;
    margin-right: 20px;
    margin-top: 15px;
}

/* Вкладка "Отчетность" */
.companyValidate__reporting__title {
    font-size: 20px;
    line-height: 20px;
    margin-top: 25px;

}
.companyValidate__reporting__subtitle {
    color:#9B9B9B;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 2px;
    line-height: 14px;
}
.companyValidate__reporting__text {
    font-size: 14px;
    line-height: 16px;
}
.companyValidate__reporting__text-none {
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
}
.companyValidate__reporting__hr {
    margin-bottom: 25px;
    color: #DADADA !important;
    background-color: #DADADA !important;
}

/* Вкладка "Проверки" */
.companyValidate__elementVerification__panel {
    background: #FDFDFD;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 3px;
    margin: 25px 0;
}
.companyValidate__elementVerification__title {
    line-height: 20px;
    font-size: 16px;
    color: #4A4A4A;
    padding: 10px 15px 0 15px;
}
.companyValidate__elementVerification__text{
    line-height: 20px;
    font-size: 12px;
    color: #979797;
    display: block;
    padding: 15px;
}
.companyValidate__elementVerification__icon{
    font-size: 26px;
    margin-right: -5px;
}
.companyValidate__elementVerification__panel>.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body {
     border-top: 0px !important;
}
.companyValidate__elementVerification__hr {
    height: 1px;
    color:#DADADA !important;
    background-color:#DADADA !important;
    width:65%;
    display: inline-block;
}

.companyValidate__elementVerification__title__information{
    margin-left: 20px;
    width: 16px;
}

/* Вкладка "Контакты" */
.companyValidate__contracts__block {
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 3px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.companyValidate__contracts_table {
    width: 100%;
}

.companyValidate__contracts_table td{
    vertical-align: top;
}

.companyValidate__contracts__textBlock {
    float: left;
    margin-right: 25px;
}
.companyValidate__contracts__title {
    line-height: 14px;
    font-size: 12px;
    color: #9B9B9B;
    margin-bottom: 2px;
}
.companyValidate__all_sum__title {
    line-height: 14px;
    font-size: 16px;
    color: #9B9B9B;
    margin-bottom: 4px;
}
.companyValidate__contracts__text {
    line-height: 16px;
    font-size: 14px;
    color: #000000;
}
.companyValidate__contracts__sumBlock {
    float: right;
    text-align: right;
    margin-left: 20px;
}
.companyValidate__contracts__sum {
    line-height: 20px;
    font-size: 20px;
    text-align: right;
    color: #000000;
}
.companyValidate__contracts__rubprice {
    line-height: 20px;
    font-size: 20px;
    text-align: right;
    color: #000000;
    margin-bottom: 4px;
}
.companyValidate__contracts__price {
    line-height: 16px;
    font-size: 16px;
    color: #9B9B9B;
}
.companyValidate__contracts__status {
    line-height: 14px;
    font-size: 12px;
    text-align: right;
    margin-top: 5px;
}
.companyValidate__contracts__fz {
    line-height: 20px;
    font-size: 10px;
    color: #4A4A4A;
    background: #FFFFFF;
    border: 1px solid #4A4A4A;
    box-sizing: border-box;
    width: 49px;
    height: 22px;
    text-align: center;
}
.companyValidate__contracts__a {
    line-height: 16px;
    font-size: 14px;
    text-decoration-line: underline;
    color: #0066A1;
}
.companyValidate__contracts-title {
    width: 800px;
    max-width: 800px;
}
.companyValidate__tass_logo {
    margin-left: 17px;
    margin-top: 35px;
}
.companyValidate__tass_icon {
}
.companyValidate__tass_text {
    margin-bottom: 5px;
    font-size: 10px;
    line-height: 14px;
    color: #2C2A70;
}
.companyValidate__tass_text .link{
    text-decoration:underline ;
    color: #2C2A70;
}
.companyValidate_customers_block {
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 15px;
}
.companyValidate_customers_block1 {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
}
.companyValidate_customers_block2 {
    border: 1px solid #DDDDDD;
    border-left: none;
    border-right: none;
    border-bottom: none;
    box-sizing: border-box;
    border-radius: 3px;
    padding-top: 15px;
    padding-left: 30px;
    /*padding-right: 30px;*/
    padding-bottom: 15px;
}
.companyValidate_customers_block1 >.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.companyValidate_customers_block2 >.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.companyValidate_customers_innkpp {
    margin-top: 10px;
}
.companyValidate_customers_name {
    font-size: 16px;
    line-height: 22px;
    color: #0066A1;
    padding-left: 0 !important;
}
.companyValidate_customers_total{
    padding-right: 0 !important;
    font-family: Verdana;
    font-size: 20px;
    line-height: 100%;
    text-align: right;
    color: #000000;
}
@media (max-width: 991px) {
    .companyValidate_customers_total {
        font-size: 16px;
    }
}
.companyValidate_customers_title {
    font-size: 14px;
    line-height: 17px;
    color: #9B9B9B;
}
.companyValidate_customers-contract{
    margin-right: 40px;
    float: left;
}
.companyValidate_customers_text {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.companyValidate_customers-contract_text {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.companyValidate__inactive{
    /*float: right;*/
    /*padding: 5px;*/
    margin-top: -7px;
    margin-left: 120px;
}
.companyValidate__search{
    background: #FFFFFF;
    border: 1px solid #0066A1;
    box-sizing: border-box;
    border-radius: 0px;
    height: 56px;
    margin-top: 20px;
    padding: 1px;
}
.companyValidate__search-text{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #4A4A4A;
    height: 56px;
    border:none;
    padding: 6px 12px;
    background-color: transparent;
    width: 100%;
}
.companyValidate__search-text::placeholder{
    color:#C0C0C0;
}
.companyValidate__search.has-error{
    border-color:#a94442 !important;
}
.companyValidate__search-btn{
    border:none;
    color:#0066A1;
    height: 56px;
    font-size: 25px;
    background: transparent;
}
.companyValidate__search-btn:hover{
    background-color: #ccc;
}
.companyValidate__search-btn:disabled{
    color:#4A4A4A;
}
.companyValidate__export_pdf_icon{
    float: right;
    font-size: 14px;
    color: #0077B9;
}

.companyValidate__export_pdf_icon i{
    padding-right: 10px;
}

.companyValidate__export_pdf_icon a{
    text-decoration: none !important;
}

.companyValidate__export_pdf_icon a:hover{
    text-decoration: underline !important;
}

.companyValidate_workers_icon{
    color: #9B9B9B;
    font-size: 17px;
    margin-top: -1px;
}
.companyValidate__taxes__wrap {
    padding-top: 0;
}
.companyValidate__taxes__block{
    margin-bottom: 15px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid; /* Makes effect only in Firefox */
    break-inside: avoid; /* IE10+, Opera 11.1—12.1 */
}

.companyValidate__taxes__block .companyValidate__taxes__block:first-child {
    margin-bottom: 15px;
}

.companyValidate__taxes__title{
    font-size: 18px;
    margin-bottom: 25px;
}

.companyValidate__taxes__mintitle{
    font-size: 14px;
    line-height: 17px;
    color: #4A4A4A;
    margin-top: 15px;
}

.companyValidate__taxes__subtitle {
    color: #9B9B9B;
    font-size: 12px;
    margin-bottom: 2px;
    line-height: 14px;
}

.companyValidate__taxes__debtam_wrap .companyValidate__taxes__block{
    margin-top: 5px;
}
.companyValidate__fin_report__table-header{
    color: #7E7E7E;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    padding-bottom: 7px;
    padding-top: 50px;
}
.companyValidate__fin_report__table-row{
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
    padding-bottom: 10px;
}
.companyValidate__fin_report__table-row_bold{
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: #4A4A4A;
    padding-top: 8px;
    padding-bottom: 10px;
}
.companyValidate__fin_report__table-row_bold.black{
    color: #000000;
}
.companyValidate__fin_report__table-block{
    padding-bottom: 10px;
}
.companyValidate__fin_report__select-year{
    font-size: 14px;
    line-height: 100%;
    text-align: right;
    color: #0066A1;
}
.companyValidate__fin_report__select-year__text{
    padding-right: 4px;
}
.companyValidate__fin_report__header{
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    color: #000000;
}
.companyValidate__fin_report__header-text{
    font-size: 14px;
    line-height: 100%;
    text-align: right;
    color: #979797;
    padding-left: 15px;
    margin-top: 4px;
}
.companyValidate__fin_report__hr{
    margin-top:0;
    margin-bottom: 10px;
}
.companyValidate__fin_report__empty-check{
    padding-bottom: 20px;
    padding-top: 20px;
}
.companyValidate__fin_report__empty-check-text{
    color: #0066A1;
    padding-left: 8px;
}
.companyValidate__fin_report__table-code{
    color: #7E7E7E;
}
.companyValidate__blocked_accounts__panel{
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 3px;
    margin: 25px 0 15px 0;
    padding: 15px;
}
.companyValidate__blocked_accounts__title__full {
    line-height: 19px;
    font-size: 16px;
    color: #E21A1A;
    padding-left: 5px;
    font-weight: bold;
}
.companyValidate__blocked_accounts__title__empty {
    line-height: 19px;
    font-size: 16px;
    color: #3DA124;
    padding-left: 5px;
    font-weight: bold;
}
.companyValidate__blocked_accounts__query_date_text{
    line-height: 20px;
    font-size: 14px;
    color: #9B9B9B;
    display: block;
    padding-left: 5px;
    padding-bottom: 5px;
}
.companyValidate__blocked_accounts__query_date_time{
    color: #000000;
}
.companyValidate__blocked_accounts__decryption_padding-bottom{
    padding-bottom: 10px;
}
.companyValidate__blocked_accounts__decryption{
    font-weight: bold;
    padding-right: 5px;
    color: #000000;
}
.companyValidate__blocked_accounts__decryption_text{
    font-size: 12px;
    color: #4A4A4A;
}
.companyValidate__blocked_accounts__decryption_title{
    color: #4A4A4A;
    padding-bottom: 10px;
}
.companyValidate__blocked_accounts__decryption_space{
    padding-left: 27px;
}

.companyValidate__blocked_accounts__captcha_title {
    line-height: 19.45px;
    font-size: 16px;
    color: #000000;
}
.companyValidate__blocked_accounts__captcha_text {
    font-size: 16px;
    font-weight: 400;
    color: #4A4A4A;
}
.companyValidate__blocked_accounts__captcha_input_row > div:first-child{
    padding-right: 0px;
}
.companyValidate__blocked_accounts__captcha_input_row > div:nth-child(2){
    padding-left: 10px;
}

.companyValidate__blocked_accounts__reload_btn{
    font-size: 14px;
    color: #0077B9;
}

.companyValidate__blocked_accounts__table{
    margin-bottom: 0px;
    color: #000000;
}
.companyValidate__blocked_accounts__table td, .companyValidate__blocked_accounts__table th{
    padding: 5px  15px  5px  5px;
}